import PageHeader from "../components/common/PageHeader";
import {Button, Card, Col, Row, Spinner, Tab, Table, Form, Alert} from "react-bootstrap";
import {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
const GPTPlugin = (props)=>{
    return (
        <>
            <PageHeader
                title="ChatGPT Plugin"
                description="Simply add this plugin and ask ChatGPT to generate avaiable domains for a business idea.  "
                className="mb-3"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>

                    <Row className="g-0">
                        <Col>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Plugin Link</Form.Label>
                                    <Form.Control type="text" placeholder="PLUGIN_LINK" value={props.baseURL} disabled={true} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </>

    )
}
export default GPTPlugin;
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import MainLayout from "./layouts/MainLayout";
import {io} from "socket.io-client";

const App = () => {

    let url = window.location.href.includes("localhost") ? "http://localhost:4001": window.location.origin;

    return (
      <>
          <Router basename={process.env.PUBLIC_URL}>
              <MainLayout baseURL = {url}/>
          </Router>
      </>
  );
};

export default App;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import dalleRobot from 'assets/img/team/dalleRobot.png';
import Avatar from 'components/common/Avatar';

const ProfileDropdown = () => {
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={dalleRobot} />
      </Dropdown.Toggle>

    </Dropdown>
  );
};

export default ProfileDropdown;

import PageHeader from "../components/common/PageHeader";
import {Button, Card, Col, Row, Spinner, Tab, Table, Form, Alert} from "react-bootstrap";
import {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";
const IdealNameUI = (props)=>{
    const [selectedTlds, setSelectedTlds] = useState(null);
    const [input,setInput] = useState("")
    const [result,setResult] = useState({});
    const [tlds,setTlds] = useState([]);
    const [buttonClicked,setButtonClicked] = useState(false);
    const [alert,setAlert] = useState({
        variant:"success",
        message:""
    })

    const onInputUpdate = (e)=>{
        if ( e.target.value.length <= 300){
            setInput(e.target.value)
        }
    }
    const onButtonClicked = (e) => {
        setButtonClicked(true)
        setAlert({
            variant:"info",
            message:"Generating names"
        })
        axios({
            method:"post",
            url:`${props.baseURL}/availability/domain/generate-names`,
            data:{
                input:input,
                tlds:selectedTlds
            }
        }).then( (response)=>{
            if ( response.data.type === "success" ){
                setResult(response.data.result)
                setAlert({
                    variant:"success",
                    message:"Successfully generated names"
                })
            }else{
                setAlert({
                    variant:"danger",
                    message:response.data.message
                })
            }
            setButtonClicked(false)
        })

    }

    useEffect(()=>{
        axios({
            method:"get",
            url:`${props.baseURL}/availability/domain/get-tlds`
        }).then( (response)=>{
            if ( response.data.type === "success" ){
                setTlds(response.data.tlds)
                setSelectedTlds([response.data.tlds[0]])
            }
        })

    },[])

    return (
        <>
            <PageHeader
                title="Welcome to PhraseFame"
                description="Easily generate catchy names on platforms and ensure availability"
                className="mb-3"
            >
            </PageHeader>
            <Card className="mb-3">
                <Card.Body>
                    {alert.message.length > 0 && <Alert key={alert.variant} variant={alert.variant}>
                        {alert.message}
                    </Alert>}
                    <Row className="g-0">
                        <Col>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Control as="textarea" rows={3}
                                                  placeholder="Write a description of your business idea and let me generate a couple good names for you!"
                                                  onChange={onInputUpdate}
                                                  value={input}
                                    />
                                    <small>{input.length}/300</small>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Select
                                        closeMenuOnSelect={false}
                                        options={tlds}
                                        placeholder='.com , .net, .org'
                                        isMulti
                                        classNamePrefix="react-select"
                                        value={selectedTlds}
                                        onChange={value => setSelectedTlds(value)}
                                    />
                                </Form.Group>
                                <Button
                                    variant={!buttonClicked ? "primary" : "danger"} className='me-2 mb-1' style={{
                                    width: '100%'
                                }}
                                    disabled={buttonClicked}
                                    onClick={onButtonClicked}
                                >{!buttonClicked.io ? "Generate" : "Please wait!"}</Button>
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="g-0">

                        <Col>
                            <Table hover responsive>
                                <thead>
                                <tr>
                                    <th scope="col">Business name</th>
                                    <th scope="col">Available domains</th>
                                    <th scope="col">Slogan</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="hover-actions-trigger" key={result.id}>
                                        <td>{result.name}</td>
                                        <td>{result.domains}</td>
                                           <td>{result.slogan}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>

    )
}
export default IdealNameUI;